import React from 'react';
import { graphql } from 'gatsby';
import Page from '../components/scenes/Page/Page/Page';
import DocumentList from '../components/scenes/DocumentList/DocumentList';
import CATEGORIES from '../constants/categories';


export default function Documents( { data, pageContext } ) {
  const category = CATEGORIES.find( settings => settings.category === pageContext.category );

  return (
    <Page heading={ category ? category.label : 'Documents' }>
      <DocumentList docs={ data.allMdx.edges } />
    </Page>
  );
}

export const query = graphql`
  query($category: String!) {
    allMdx(
      filter: {fields: {category: {eq: $category}}}
      sort: {order: ASC, fields: frontmatter___order}
    ) {
      edges {
        node {
          fields {
            category
            slug
          }
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;