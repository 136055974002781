import React from 'react';
import { Link } from 'gatsby';
import * as styles from './document-list.module.scss';
import Arrow from '../../../assets/images/svg/arrow.svg';


export default function DocumentList( { docs } ) {
  return (
    <ul className={ styles.documentList }>
      { docs.map( ( { node } ) => (
        <li key={ node.id } className={ styles.documentListItem }>
          <Link to={ node.fields.slug } className={ styles.documentListLink }>
            <h3 className={ styles.documentListTitle }>
              { node.frontmatter.title }
            </h3>
            <span>
              Last Update: { node.frontmatter.date }
            </span>
            <Arrow className={ styles.documentListArrow } />
          </Link>
        </li>
      ) ) }
    </ul>
  );
}